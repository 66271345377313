/*
 * Copyright (C) MetaCarp GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Allan Amstadt <a.amstadt@metacarp.de, 2017-2023
 * Written by Peter Seifert <p.seifert@metacarp.de>, 2017-2023
 */

(window as any)["__zone_symbol__PASSIVE_EVENTS"] = ["scroll"];
(window as any)["__Zone_disable_requestAnimationFrame"] = true;
